import React from 'react'
import { Helmet } from 'react-helmet'


const Seo = ( data ) => {

    return (
        <>
        <Helmet>

            <html lang={data.en === true ? 'en' : 'nl'} />
            <meta property="og:locale" content={data.en === true ? 'en' : 'nl'} />

            {data.title && <title>{data.title}</title> }

            {data.title && <meta name="title" content={`${data.title}`} /> }
            {data.title && <meta property="og:title" content={`${data.title}`} /> }
            {data.title && <meta property="twitter:title" content={`${data.title}`} /> }

            <meta property="og:site_name" content="WA.nl" />

            <meta property="twitter:card" content="summary_large_image" />

            {data.url && <meta property="og:url" content={`https://www.wa.nl${data.url}`} /> }
            {data.url && <meta property="twitter:url" content={`https://www.wa.nl${data.url}`} /> }

            {data.noindex === true && <meta name="robots" content="noindex" />}

            {data.type && <meta property="og:type" content={data.type} /> }

            {data.thumbnail && <meta property="og:image" content={data.thumbnail} /> }
                {data.thumbnail === "" && <meta property="og:image" content="/wa-thumbnail.png" />}
            {data.thumbnail && <meta property="twitter:image" content={data.thumbnail} /> }
                {data.thumbnail === "" && <meta property="twitter:image" content="/wa-thumbnail.png" /> }

            {data.description && <meta name="description" content={data.description} /> }
            {data.description && <meta property="og:description" content={data.description} /> }
            {data.description && <meta property="twitter:description" content={data.description} />}

            {data.alternateSlug && data.alternateSlug !== '/undefined' &&
                <link data-react-helmet="true" rel="alternate" hreflang={data.en === true ? 'en' : 'nl'} href={`https://www.wa.nl${data.url}`} />
            }

            {data.alternateSlug && data.alternateSlug !== '/undefined' && 
                <link data-react-helmet="true" rel="alternate" hreflang={data.en === true ? 'nl' : 'en'} href={`https://www.wa.nl${data.alternateSlug}`} />
            }

            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />

        </Helmet>
        </>
    )
}

export default Seo