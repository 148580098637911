// Gatsby Requirements
import React from 'react'
import { storyblokInit } from "gatsby-source-storyblok"

// Layout files
import Footer from './footer'
import Header from './header'
import '../../styles/global.scss'

storyblokInit({
    accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
});

const Layout = (props) => {
    return(
        <>
            <Header en={props.en} alternateSlug={props?.alternateSlug}/>
                {props.children}
            <Footer en={props.en}/>
        </>
    )
}

export default Layout