// Gatsby requirements
import React from "react"

// Components that are connected to Storyblok
import Hero from 'components/sectionHero'
import Cards from 'components/sectionCards'
import TextAndImage from 'components/sectionTextAndImage'
import Logos from 'components/sectionLogos'
import CategoryList from 'components/sectionCategoryList'
import Testimonial from 'components/sectionTestimonial'
import Faq from 'components/sectionFaq'
import Cta from 'components/sectionCta'
import Table from 'components/sectionTable'
import AdList from 'components/sectionAdList'
import NumberedVerticalCards from 'components/sectionNumberedVerticalCards'
import ListOfArticles from 'components/sectionListOfArticles'
import Breadcrumbs from 'components/breadcrumbs'
import PricingCards from 'components/sectionPricingCards'
import ContactForm from 'components/sectionContactForm'
import Timeline from 'components/sectionTimeline'
import ProductComparison from 'components/sectionProductComparison'
import ShareButtons from 'components/sectionShareButtons'
import Html from 'components/sectionHtml'
import SectionReviews from 'components/sectionReviews'
import SectionReviewForm from 'components/sectionReviewForm'
import SectionProsAndCons from 'components/sectionProsAndCons'

const StoryblokComponents = ({ content, currentPage, currentPageName, en  }) => (
  
    content?.map((blok) => {
        switch (blok.component) {
            case 'Section: Hero': return <Hero en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Cards': return <Cards en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Text and Image': return <TextAndImage en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Logos': return <Logos en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Category List': return <CategoryList en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Testimonial': return <Testimonial en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Faq': return <Faq en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Call to Action': return <Cta en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Table': return <Table en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Ad List': return <AdList en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Numbered Vertical Cards': return <NumberedVerticalCards en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: List of Articles': return <ListOfArticles en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Breadcrumbs': return <span><Breadcrumbs en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} /></span>
            case 'Section: Pricing Cards': return <PricingCards en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Contact Form': return <ContactForm en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Timeline': return <Timeline en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Product Comparison': return <ProductComparison en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Share Buttons': return <ShareButtons en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Html': return <Html en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Reviews': return <SectionReviews en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Pros and Cons': return <SectionProsAndCons en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
            case 'Section: Review Form': return <SectionReviewForm en={en} currentPageName={currentPageName} currentPage={currentPage}  key={blok._uid} blok={blok} />
        }
    })
)

export default StoryblokComponents