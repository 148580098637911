// Gatsby requirements
import React, { useState, useEffect } from "react"
import { Link } from 'gatsby'
 
// Components
import Layout from "../components/layout/layout"
import Seo from "../components/seo"

// Storyblok preview
import { storyblokEditable } from "gatsby-source-storyblok"

// Storyblok Components
import StoryblokComponents from '../utils/StoryblokComponents'
 
const NotFoundPage = () => {

  const [previewStory, setPreviewStory] = useState();
  const [isEnglish, setIsEnglish] = useState(false);
  
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {
        const url = `https://api.storyblok.com/v2/cdn/stories/${window.location.pathname}?version=draft&token=${process.env.GATSBY_STORYBLOK_ACCESS_TOKEN}&cv=${Date.now()}`;
    
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                setPreviewStory(json.story);
            } catch (error) {
                console.log("error", error);
            }
        };
    
        fetchData();

        setTimeout(() => {
          const sbBridge = new window.StoryblokBridge();
          sbBridge.on(["input", "published", "change"], (event) => {
            setPreviewStory(event.story);
          });
        }, 1000);

    }
  }, []);

  useEffect(() => {
    if (window.location.pathname.startsWith('/en/')) {
      setIsEnglish(true);
    }
  }, []);
 
  return (
    <Layout en={isEnglish}>

      <Seo
          title="404 – Pagina niet gevonden"
          description="Pagina niet gevonden"
      />

      {!previewStory?.content &&
        <main id="content" style={{textAlign: 'center', margin: '5em 1em'}}>
          {!isEnglish ?
            <>
              <h1>Pagina niet gevonden</h1>
              <p style={{maxWidth: '20em', margin: '-1em auto 1em auto'}}>De pagina die u zoekt is mogelijk ergens anders naartoe verhuisd of het adres dat u heeft ingevoerd was onjuist.</p>
              <Link to="/" className="button">Terug naar de homepage</Link>
            </>
            :
            <>
              <h1>Page not found</h1>
              <p style={{maxWidth: '20em', margin: '-1em auto 1em auto'}}>The page you are looking for may have moved somewhere else or the address you entered was incorrect.</p>
              <Link to="/" className="button">Back to the homepage</Link>
            </>
          }
        </main>
      }

      {previewStory?.content &&
        <main id="content" {...storyblokEditable(previewStory?.content)}>
          <StoryblokComponents content={previewStory?.content?.blocks}/>
        </main>
      }

    </Layout>
  )
}
 
export default NotFoundPage