// Gatsby requirements
import React, { useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// Components
import RichTextRenderer from 'utils/richTextRenderer'

// Styles
import * as CSS from './footer.module.scss'


const Footer = ({ en, previewData }) =>  {

    // Footer data query
    const data = useStaticQuery(graphql`
        query {
            footer: storyblokEntry(field_component: {eq: "Footer"}, slug: {eq: "footer"}) {
                content
                id
                uuid
                internalId
            }
            footer_en: storyblokEntry(field_component: {eq: "Footer"}, slug: {eq: "footer-en"}) {
                content
                id
                uuid
                internalId
            }
        }
    `)
    
    // Display "goToTop" after scrolling
    useEffect(() => {
        const myID = document.getElementById("goToTop");

        var myScrollFunc = function() {
        var y = window.scrollY;
        if (y >= 400) {
            myID.className = "show"
        } else {
            myID.className = "hide"
        }
        };

        window.addEventListener("scroll", myScrollFunc);
    }, []);

    // Footer content to parsed JSON
    const jsonData = previewData?.content || JSON.parse(data.footer.content);
    const jsonDataEn = previewData?.content || JSON.parse(data.footer_en.content)

    return (
        <footer className={CSS.footer}>

            <a href="#top" id="goToTop" aria-label="Go to top"></a>

            <div className="wrapper">
                <div>
                    <Link to={en === true ? '/en/' : '/'}>
                        <img src="/wa-logo.svg" height="90" width="53" alt="WA.nl logo" />
                    </Link>
                    <RichTextRenderer data={en === false ? jsonData?.richtext : jsonDataEn?.richtext} />
                </div>
                <RichTextRenderer data={en === false ? jsonData?.second_area : jsonDataEn?.second_area} />
                <RichTextRenderer data={en === false ? jsonData?.third_area : jsonDataEn?.third_area} />
                <RichTextRenderer data={en === false ? jsonData?.fourth_area : jsonDataEn?.fourth_area} />
            </div>

            <RichTextRenderer className="wrapper footer-bottom-text" data={en === false ? jsonData?.bottom_text : jsonDataEn?.bottom_text} />

        </footer>
    )
}

export default Footer